<template>
  <div><Provider /></div>
</template>

<script>
import Provider from '@/modules/provider/Provider.vue'

export default {
  components: {
    Provider,
  },
}
</script>

<style>

</style>
